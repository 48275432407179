import Vue from 'vue';
import Component from 'vue-class-component';
import {Inject, Prop} from 'vue-property-decorator';
import {IStationState} from './station-state-dto-mapper';
import {ApiClient} from './api-client';

@Component({
    template: require('./radar-state-switcher.html')
})
export class RadarStateSwitcher extends Vue {

    @Prop()
    private stationState: IStationState;

    @Inject()
    private apiClient: ApiClient;

    private postingStateChange = false;

    private requestStateChange(newState: boolean) {
        this.postingStateChange = true;
        return this.apiClient.requestRadarState(newState).then(() => this.requestCompleted());
    }

    private requestCompleted() {
        this.postingStateChange = false;
    }
}
