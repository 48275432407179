import {Moment} from 'moment';
import * as moment from 'moment';

export class StationStateDtoMapper {

    public mapDto(dto: IStationStateDto): IStationState {
        return {
            currentRadarState: dto.currentRadarState != null ? this.mapSubsystemState(dto.currentRadarState) : null,
            requestedRadarState: dto.requestedRadarState != null ? this.mapSubsystemState(dto.requestedRadarState) : null,
            pictureRequest: dto.pictureRequest != null ? this.mapPictureRequestState(dto.pictureRequest) : null
        };
    }

    private mapSubsystemState(dto: ISubsystemStateDto): ISubsystemState {
        return {
            timestamp: moment(dto.timestamp),
            state: dto.state,
            requestedBy: dto.requestedBy
        };
    }

    private mapPictureRequestState(dto: IPictureRequestStateDto): IPictureRequestState {
        return {
            timestamp: moment(dto.timestamp),
            requestedBy: dto.requestedBy
        };
    }
}

export interface IStationStateDto {
    currentRadarState: ISubsystemStateDto | null;
    requestedRadarState: ISubsystemStateDto | null;
    pictureRequest: IPictureRequestStateDto | null;
}

export interface ISubsystemStateDto {
    timestamp: string;
    state: boolean;
    requestedBy: string;
}

export interface IPictureRequestStateDto {
    timestamp: string;
    requestedBy: string;
}

export interface IStationState {
    currentRadarState: ISubsystemState | null;
    requestedRadarState: ISubsystemState | null;
    pictureRequest: IPictureRequestState | null;
}

export interface ISubsystemState {
    timestamp: Moment;
    state: boolean;
    requestedBy: string;
}

export interface IPictureRequestState {
    timestamp: Moment;
    requestedBy: string;
}
