
import {RadarStateInfo} from './radar-state-info';
import Vue from 'vue';
import Component from 'vue-class-component';
import {Inject} from 'vue-property-decorator';
import {ConnectionTracker} from './connection-tracker';

@Component({
    template: require('./connection-state-panel.html'),
    components: { AlarmStateInfo: RadarStateInfo }
})
export class ConnectionStatePanel extends Vue {

    @Inject()
    private connectionTracker: ConnectionTracker;

    // The connection state panel is disabled when navigating away (FOUC avoidance)
    private enabled: boolean = true;

    private connected: boolean = false;

    private tryingToReconnect: boolean = false;

    private activityIndicator = false;

    constructor() {
        super();

        this.connectionTracker.registerConnectionObserver((c, r) => this.updateConnectionState(c, r));

        setInterval(() => this.toggleActivityIndicator(), 750);
    }

    private updateConnectionState(connected: boolean, reconnecting: boolean) {
        this.connected = connected;
        this.tryingToReconnect = reconnecting;
    }

    private toggleActivityIndicator() {
        this.activityIndicator = !this.activityIndicator;
    }

    public mounted() {
        window.addEventListener('beforeunload', () => this.onWindowUnload());
    }

    private onWindowUnload() {
        this.enabled = false;
    }
}

