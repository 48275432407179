
import Component from 'vue-class-component';
import Vue from 'vue';
import {Inject} from 'vue-property-decorator';
import {ApiClient} from './api-client';
import {IStationState} from './station-state-dto-mapper';
import {StationStateTracker} from './station-state-tracker';
import * as moment from 'moment';
import {Moment} from 'moment';

@Component({
    template: require('./take-picture-panel.html')
})
export class TakePicturePanel extends Vue {

    @Inject()
    private stationStateTracker: StationStateTracker;

    @Inject()
    private apiClient: ApiClient;

    private stationState: IStationState | null = null;

    private requestingPicture = false;

    private picturesEnabled = true;

    private now: Moment = moment();

    constructor() {
        super();

        this.stationStateTracker.registerAlarmStateCallback(s => {
            this.updateStationState(s);
        });

        setInterval(() => this.updateNow(), 3 * 1000);
    }

    public takePicture() {
        this.requestingPicture = true;
        this.apiClient.requestPicture().then(() => this.pictureRequested());
    }

    private pictureRequested() {
        this.requestingPicture = false;
        this.picturesEnabled = false;

        setTimeout(() => {
            this.reenablePictureTaking();
        }, 30 * 1000);
    }

    private reenablePictureTaking() {
        this.picturesEnabled = true;
    }

    private updateStationState(stationState: IStationState) {
        this.stationState = stationState;
    }

    private updateNow() {
        this.now = moment().subtract('3s');
    }
}
