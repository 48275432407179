import Component from 'vue-class-component';
import Vue from 'vue';
import {Inject} from 'vue-property-decorator';
import {StationStateTracker} from './station-state-tracker';
import {RadarStateInfo} from './radar-state-info';
import {IStationState} from './station-state-dto-mapper';
import {RadarStateSwitcher} from './radar-state-switcher';

@Component({
    template: require('./radar-state-indicator.html'),
    components: {
        RadarStateInfo,
        RadarStateSwitcher
    }
})
export class RadarStateIndicator extends Vue {

    @Inject()
    private stationStateTracker: StationStateTracker;

    public stationState: IStationState | null = null;

    constructor() {
        super();

        this.stationStateTracker.registerAlarmStateCallback(s => {
            this.updateStationState(s);
        });
    }

    private updateStationState(stationState: IStationState) {
        this.stationState = stationState;
    }

}
