
import axios, {AxiosInstance} from 'axios';

export class ApiClient {

    private client: AxiosInstance;

    constructor(baseUrl: string, apiToken: string) {

        this.client = axios.create({
            baseURL: baseUrl,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `GeoHub ${apiToken}`
            }
        });
    }

    public requestRadarState(newState: boolean): Promise<void> {
        return this.client.put('api/v1/gonda/radar', { state: newState })
            .then(r => r.data);
    }

    public requestPicture() {
        return this.client.post('api/v1/gonda/take-picture', { })
            .then(r => r.data);
    }
}

