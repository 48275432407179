
import {ConnectionTracker} from './connection-tracker';
import {StationStateDtoMapper, IStationState, IStationStateDto} from './station-state-dto-mapper';
import {StationStateLogsDtoMapper, IStationStateLogsDto} from './station-state-logs-dto-mapper';

export class StationStateTracker {

    private stateCallbacks: Array<((update: IStationState, initialState: boolean) => void)> = [];

    constructor(
        private connectionTracker: ConnectionTracker,
        private stationStateDtoMapper: StationStateDtoMapper,
        private stationStateLogsDtoMapper: StationStateLogsDtoMapper) {

        this.connectionTracker.registerHandler(
            'StationStateUpdate',
            dto => this.onStationStateUpdate(dto));

        // The initial state is read from the logs, so we register that handler as well
        this.connectionTracker.registerHandler(
            'StationStateLogs',
            dto => this.onStationStateLogs(dto));
    }

    private onStationStateUpdate(dto: IStationStateDto) {

        let stationState = this.stationStateDtoMapper.mapDto(dto);

        this.stateCallbacks.forEach(c => c(stationState, false));
    }

    private onStationStateLogs(dto: IStationStateLogsDto) {

        let logs = this.stationStateLogsDtoMapper.mapDto(dto);

        let current = logs[0];

        this.stateCallbacks.forEach(c => c(current.stationState, true));
    }

    public registerAlarmStateCallback(callback: ((update: IStationState, initialState: boolean) => void)) {
        this.stateCallbacks.push(callback);
    }
}

